// We haven't added icon's computed property because it makes this mixin coupled with UI
export const translationUtils = {
  methods: {
    translateOptions(options) {
      const translatedOptions = options
      options.forEach((item, index) => {
        translatedOptions[index].name = this.$i18n.t(item.name)
      })
      return translatedOptions
    },
  },
}

export const _ = null
