<template>
  <div v-if="lat && lng">
    <GmapMap
      :center="center"
      :zoom="15"
      map-type-id="roadmap"
    >
      <GmapMarker
        :position="center"
        :animation="animation"
      />
    </GmapMap>
  </div>
</template>

<script>
export default {
  props: {
    lat: {
      type: Number,
      default: null,
    },
    lng: {
      type: Number,
      default: null,
    },
    entity: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      fetchedAddress: {
        state: '',
        city: '',
        address: '',
        region: '',
        postalCode: '',
        country: '',
      },
      fetchNewCoords: null,
      animation: 2,
    }
  },
  computed: {
    center() {
      return {
        lat: this.lat,
        lng: this.lng,
      }
    },

    watchFor() {
      return [
        'address',
        'postalCode',
      ]
    },
  },
  watch: {
    entity: {
      handler(v) {
        let update = false

        this.watchFor.forEach(property => {
          if (v[property] !== this.fetchedAddress[property]) {
            clearTimeout(this.fetchNewCoords)
            update = true
          }
        })

        if (update) {
          this.fetchNewCoords = setTimeout(() => {
            this.fetchCoords()
          }, 500)
        }
      },
      deep: true,
    },
  },
  created() {
    this.fetchCoords()
  },
  methods: {
    fetchCoords() {
      this.fetchedAddress = {
        state: (this.entity.prefecture) ? this.entity.prefecture.title : '',
        city: this.entity.city,
        address: this.entity.address,
        region: this.entity.region,
        postalCode: this.entity.postalCode,
        country: (this.entity.country) ? this.entity.country.title : '',
      }

      this.animation = 1

      this.$geocoder.send({
        address_line_1: this.fetchedAddress.address,
        address_line_2: this.fetchedAddress.region,
        city: this.fetchedAddress.city,
        state: this.fetchedAddress.state,
        zip_code: this.fetchedAddress.postalCode,
        country: this.fetchedAddress.country,
      }, res => {
        const [place] = res.results
        if (place) {
          this.$emit('update:lat', place.geometry.location.lat)
          this.$emit('update:lng', place.geometry.location.lng)

          this.animation = 2
        }
      })
    },
  },
}
</script>

<style lang="scss">
.vue-map-container {
  height: 350px;
}
</style>
