<template>
  <EntitySingle
    :config="config"
    :entity.sync="entity"
    :loaded.sync="entityLoaded"
    :extra-actions="extraActions"
    store-entity
  >
    <b-card v-if="entityLoaded">
      <b-tabs pills>
        <!-- Tab: Information -->
        <b-tab active>
          <template #title>
            <!-- eslint-disable-next-line vue/max-attributes-per-line -->
            <feather-icon icon="InfoIcon" size="16" class="mr-0 mr-sm-50" />
            <span class="d-none d-sm-inline">{{ $t('Information') }}</span>
          </template>
          <EntityEditSection
            :module="config.module"
            :entity.sync="entity"
            :form-fields="infoFormFields"
            allow-autofocus
          />
        </b-tab>
        <b-tab>
          <template #title>
            <feather-icon icon="LinkIcon" size="16" class="mr-0 mr-sm-50" />
            <span class="d-none d-sm-inline">{{ $t('Contact') }}</span>
          </template>
          <EntityEditSection
            :module="config.module"
            :entity.sync="entity"
            :form-fields="contactFormFields"
          />
        </b-tab>
        <b-tab>
          <template #title>
            <feather-icon icon="AlignCenterIcon" size="16" class="mr-0 mr-sm-50" />
            <span class="d-none d-sm-inline">{{ $t('Description') }}</span>
          </template>
          <EntityEditSection
            :module="config.module"
            :entity.sync="entity"
            :form-fields="descriptionFormFields"
          />
        </b-tab>
        <b-tab>
          <template #title>
            <!-- eslint-disable-next-line vue/max-attributes-per-line -->
            <feather-icon icon="MailIcon" size="16" class="mr-0 mr-sm-50" />
            <span class="d-none d-sm-inline">{{ $t('Email') }}</span>
          </template>
          <EntityEditSection
            :module="config.module"
            :entity.sync="entity"
            :form-fields="emailFormFields"
          />
        </b-tab>
        <b-tab>
          <template #title>
            <feather-icon icon="TrelloIcon" size="16" class="mr-0 mr-sm-50" />
            <span class="d-none d-sm-inline">{{ $t('Media') }}</span>
          </template>
          <EntityEditSection
            :module="config.module"
            :entity.sync="entity"
            :form-fields="logoFormFields"
          />
        </b-tab>
        <!-- <b-tab>
          <template #title>
            <feather-icon icon="SlidersIcon" size="16" class="mr-0 mr-sm-50" />
            <span class="d-none d-sm-inline">{{ $t('Abstracts') }}</span>
          </template>
          <EntityEditSection
            :module="config.module"
            :entity.sync="entity"
            :form-fields="abstractFormFields"
          />
        </b-tab> -->
        <!-- <b-tab>
          <template #title>
            <feather-icon icon="FilePlusIcon" size="16" class="mr-0 mr-sm-50" />
            <span class="d-none d-sm-inline">{{ $t('Attachments') }}</span>
          </template>
          <EntityEditSection
            :module="config.module"
            :entity.sync="entity"
            :form-fields="attachmentsFormFields"
          />
        </b-tab> -->
      </b-tabs>
    </b-card>
  </EntitySingle>
</template>

<script>
import { BTab, BTabs, BCard } from 'bootstrap-vue'
import EntityEditSection from '@/layouts/entity/EntityEditSection.vue'
import EntitySingle from '@/layouts/entity/EntitySingle.vue'
import { translationUtils } from '@core/mixins/ui/translations'
import storeModule from '../CongressStoreModule'

export default {
  components: {
    EntityEditSection,
    EntitySingle,
    BTab,
    BTabs,
    BCard,
  },
  mixins: [translationUtils],
  data() {
    return {
      entity: {
        enabled: true,
        status: { id: 1, title: 'new' },
        country: { id: 263, title: 'Greece' },
        language: { id: 2, title: 'Ελληνικά' },
      },
      entityLoaded: false,
      categoryOptions: [],
      statusOptions: [],
      venueOptions: [],
      // periodOptions: [],
      countryOptions: [],
      languageOptions: [],
      // organizerOptions: [],
      specialtiesOptions: [],
      userOptions: [],
    }
  },
  computed: {
    config() {
      return {
        store: storeModule,
        module: 'congresses',
        endpoint: 'congresses',
        route: 'congresses',
        title: {
          single: this.$t('Congress Add'),
          plural: this.$t('Congresses'),
        },
      }
    },

    infoFormFields() {
      return [
        {
          id: 'title',
          type: 'text',
          label: this.$i18n.t('Title'),
          required: true,
          rules: 'min:3',
          colSize: 6,
        },
        // {
        //   id: 'organizer',
        //   type: 'select',
        //   object: true,
        //   label: this.$i18n.t('organizer'),
        //   required: true,
        //   options: this.organizerOptions,
        //   colSize: 6,
        // },
        {
          id: 'venue',
          type: 'select',
          object: true,
          label: this.$i18n.t('venue'),
          // required: true,
          options: this.venueOptions,
          colSize: 6,
        },
        {
          id: 'status',
          type: 'select',
          object: true,
          label: this.$i18n.t('Status'),
          required: true,
          options: this.statusOptions,
          colSize: 6,
        },
        {
          id: 'category',
          type: 'select',
          object: true,
          label: this.$i18n.t('Category'),
          required: true,
          options: this.categoryOptions,
          colSize: 6,
        },
        {
          id: 'startDate',
          type: 'datetime',
          label: this.$t('Start Date'),
          refMaxDate: 'endDate',
          required: true,
          colSize: 4,
        },
        {
          id: 'endDate',
          type: 'datetime',
          label: this.$t('End Date'),
          refMinDate: 'startDate',
          required: true,
          colSize: 4,
        },
        // {
        //   id: 'finalizeDate',
        //   type: 'date',
        //   label: this.$t('finalizeDate'),
        //   colSize: 3,
        // },
        // {
        //   id: 'registrationPeriod',
        //   object: true,
        //   type: 'selectmultiple',
        //   label: this.$t('registrationPeriod'),
        //   options: this.periodOptions,
        //   required: true,
        //   colSize: 4,
        // },
        // {
        //   id: 'specialties',
        //   type: 'selectmultiple',
        //   object: true,
        //   label: this.$i18n.t('Specialties'),
        //   options: this.specialtiesOptions,
        //   colSize: 6,
        // },
        {
          id: 'language',
          type: 'select',
          object: true,
          label: this.$i18n.t('Language'),
          required: true,
          options: this.languageOptions,
          colSize: 4,
        },
        {
          id: 'enabled',
          type: 'checkbox',
          label: this.$t('enabled'),
          colSize: 2,
        },
      ]
    },
    contactFormFields() {
      return [
        {
          id: 'country',
          type: 'select',
          object: true,
          label: this.$i18n.t('country'),
          required: true,
          options: this.countryOptions,
          colSize: 6,
        },
        {
          id: 'city',
          type: 'text',
          label: this.$i18n.t('city'),
          colSize: 6,
        },
        {
          id: 'email',
          type: 'email',
          label: this.$i18n.t('email'),
          rules: 'email',
          colSize: 6,
        },
        {
          id: 'website',
          type: 'text',
          label: this.$t('website'),
          rules: 'url',
          colSize: 6,
        },
        // {
        //   id: 'user',
        //   type: 'select',
        //   object: true,
        //   label: this.$i18n.t('UserInCharge'),
        //   // required: true,
        //   options: this.userOptions,
        //   colSize: 6,
        // },
      ]
    },
    descriptionFormFields() {
      return [
        {
          id: 'description',
          type: 'quill',
          label: this.$t('description'),
          colSize: 12,
        },
        {
          id: 'shortDescription',
          type: 'textarea',
          label: this.$t('shortDescription'),
          colSize: 12,
        },
      ]
    },
    emailFormFields() {
      return [
        {
          id: 'emailFromAddress',
          type: 'email',
          label: this.$i18n.t('emailFromAddress'),
          rules: 'email',
          colSize: 6,
        },
        {
          id: 'emailFromName',
          type: 'text',
          label: this.$i18n.t('emailFromName'),
          colSize: 6,
        },
        {
          id: 'emailBody',
          type: 'quill',
          label: this.$i18n.t('emailBody'),
          colSize: 12,
        },
      ]
    },
    logoFormFields() {
      return [
        {
          id: 'logo',
          type: 'cropupload',
          label: `${this.$t('Image')} Logo (512x512)`,
          layoutPreview: 'logo',
          colSize: 6,
          width: 512,
          height: 512,
          autoupload: true,
        },
        {
          id: 'bgBanner',
          type: 'cropupload',
          label: `${this.$t('Image')}  Banner (1170x658)`,
          layoutPreview: 'banner',
          colSize: 6,
          width: 1170,
          height: 658,
          autoupload: true,
        },
      ]
    },
    abstractFormFields() {
      return [
        {
          id: 'submissionDeadline',
          type: 'datetime',
          label: this.$t('submissionDeadline'),
          refMaxDate: 'startDate',
          required: true,
          colSize: 4,
        },
        {
          id: 'excerptLength',
          type: 'text',
          label: this.$i18n.t('excerptLength'),
          rules: 'positive',
          colSize: 4,
        },
        {
          id: 'extendedExcerptLength',
          type: 'text',
          label: this.$i18n.t('extendedExcerptLength'),
          rules: 'positive',
          colSize: 4,
        },
      ]
    },
    attachmentsFormFields() {
      return [
        // {
        //   id: 'logo',
        //   type: 'cropupload',
        //   label: `${this.$t('Image')} Logo (512x512)`,
        //   layoutPreview: 'logo',
        //   colSize: 6,
        //   width: 500,
        //   height: 500,
        //   autoupload: true,
        // },
      ]
    },
    extraActions() {
      return [
        {
          icon: 'EyeIcon',
          title: 'View in website',
          href: this.entity.websiteSlug,
          variant: 'outline-secondary',
          renderCondition: {
            key: 'published',
            value: true,
          },
        },
        {
          endpoint: 'website/publish',
          icon: 'UploadCloudIcon',
          title: 'Publish to website',
          renderCondition: {
            key: 'published',
            value: false,
          },
        },
        {
          // callback: 'updatewebsite',
          endpoint: 'website/publish',
          icon: 'UploadCloudIcon',
          title: 'Update website',
          renderCondition: {
            key: 'published',
            value: true,
          },
        },
        {
          endpoint: 'website/unpublish',
          icon: 'DownloadCloudIcon',
          title: 'Remove from website',
          variant: 'danger',
          renderCondition: {
            key: 'published',
            value: true,
          },
        },
      ]
    },
  },
  mounted() {
    this.$store.dispatch('app-common/fetchCountries').then(response => {
      this.countryOptions = response.data
    })
    this.$store.dispatch('app-common/fetchCongressCategories').then(response => {
      this.categoryOptions = response.data
    })
    this.$store.dispatch('app-common/fetchCongressStatuses').then(response => {
      this.statusOptions = response.data
    })
    // this.$store.dispatch('app-common/fetchRegistrationPeriods').then(response => {
    //   this.periodOptions = response.data
    // })
    this.$store.dispatch('app-common/fetchVenues').then(response => {
      this.venueOptions = response.data
    })
    // this.$store.dispatch('app-common/fetchCompanies')
    //   .then(response => {
    //     this.organizerOptions = response.data
    //   })
    this.$store.dispatch('app-common/fetchLanguages').then(response => {
      this.languageOptions = response.data
    })
    this.$store.dispatch('app-common/fetchSpecialties').then(response => {
      this.specialtiesOptions = response.data
    })
    this.$store.dispatch('app-common/fetchUsers').then(response => {
      this.userOptions = response.data
    })
  },
}
</script>
